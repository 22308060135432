/* General reset and styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    color: #333;
  }
  
  main {
    padding: 20px;
  }
  
  /* Header styling */
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #444;
  }
  
  /* Container for service cards */
  main > div {
    display: flex;
    flex-direction: column;
    /* gap: 1px; Space between the cards */
    border: px solid red;
  }
  
  /* Individual service card */
  .service-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* padding: 10px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box; /* Include padding in the width */
  }
  
  .service-card-container {
    padding: 0 15px;
  }
  