.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003366; 
}

.nav-left {
  flex: 1;
  display: flex;
  align-items: center;
}

.nav-left img {
  height: 75px; 
  width: auto; 
}

.nav-right ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center; 
}

.nav-right li {
  margin-left: 20px; 
  display: flex; 
  align-items: center; 
}

.nav-right li svg {
  margin-right: 8px; 
  font-size: 1.2rem; 
}

.nav-right a {
  color: white; 
  text-decoration: none;
  font-size: 16px; 
  display: inline-block; 
}

@media (max-width: 768px) {
  .navbar {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 10px 0;
  }

  .nav-left img {
      height: 50px; 
  }

  .nav-right ul {
      flex-direction: column; 
      align-items: flex-start; 
      margin-top: 15px; 
  }

  .nav-right li {
      margin-left: 0; 
      margin-bottom: 10px; 
  }

  .nav-right a {
      font-size: 14px; 
  }

  .nav-right li svg {
      font-size: 1rem; 
  }
}